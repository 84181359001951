<template>
  <b-overlay :show="isLoadingStaffContact || isUpdatingStaffContact">
    <b-card>
      <div class="head-btn">
        <b-button
          :to="{ name: 'staff-contact'}"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="warning"
          > <feather-icon icon="ArrowLeftIcon" style="color: white" />
        </b-button>
      </div>
      <div v-if="staffContactData && !isLoadingStaffContact">
        <ValidationObserver
          ref="formUpdateStaffContact"
          v-slot="{ invalid }"
        >
          <b-form @submit.prevent="update">
            <b-row>
              <b-col sm="6" md="4">

                <div class="upload__img-wrap justify-content-center">
                  <div class="upload__img-box">
                    <span
                      :href="sourceFile"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div :style="`background-image: url('${sourceFile}');`" class='img-bg' v-if="!!sourceFile">
                        <a class='upload__img-close' @click="deleteImageLocal" v-if="!!file"></a>
                        <a class='upload__img-close' @click="deleteImage" v-else-if="!!staffContactData.profilepicture"></a>
                      </div>
                      <div v-else class="default-image"></div>
                    </span>

                  </div>
                </div>

                <div class="d-flex justify-content-center">
                  <ValidationProvider rules="" name="imagen de perfil">
                    <b-form-group slot-scope="{ valid, errors }" class="pl-1 pr-1">
                      <b-form-file
                        v-model="file"
                        placeholder="Escoge un archivo o sueltalo aquí..."
                        drop-placeholder="Suelta el archivo aquí..."
                        accept=".jpg, .png, .jpeg"
                        class="btn-upload-file"
                        :state="errors[0] ? false : valid ? true : null"
                        @change="deleteImage"
                        browse-text="Buscar archivo"
                      />
                      <b-form-invalid-feedback>{{errors[0]}}</b-form-invalid-feedback>
                    </b-form-group>
                  </ValidationProvider>

                </div>
              </b-col>

              <b-col>
                <b-row>
                  <b-col class="label-text" md="6">
                    <validation-provider name="Nombre" rules="required">
                      <b-form-group slot-scope="{ valid, errors }">
                        <label>Nombre</label>
                        <b-form-input
                          v-model="staffContactData.name"
                          :state="errors[0] ? false : valid ? true : null"
                        />
                        <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col class="label-text" md="6">
                    <validation-provider name="Apellido" rules="required">
                      <b-form-group slot-scope="{ valid, errors }">
                        <label>Apellido</label>
                        <b-form-input
                          v-model="staffContactData.lastname"
                          :state="errors[0] ? false : valid ? true : null"
                        />
                        <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col class="label-text" md="6">
                    <validation-provider name="Puesto" rules="required">
                      <b-form-group slot-scope="{ valid, errors }">
                        <label>Puesto</label>
                        <b-form-input
                          v-model="staffContactData.jobTitle"
                          :state="errors[0] ? false : valid ? true : null"
                        />
                        <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col class="label-text" md="6">
                    <validation-provider name="Puesto en inglés" rules="required">
                      <b-form-group slot-scope="{ valid, errors }">
                        <label>Puesto (Inglés)</label>
                        <b-form-input
                          v-model="staffContactData.jobTitleEng"
                          :state="errors[0] ? false : valid ? true : null"
                        />
                        <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col class="label-text" md="6">
                    <validation-provider name="Email" rules="">
                      <b-form-group slot-scope="{ valid, errors }">
                        <span class="d-flex justify-content-between">
                          <label>Email</label> <label>Mostrar en web</label>
                        </span>
                        <b-input-group>
                          <b-form-input
                            v-model="staffContactData.email"
                            :state="errors[0] ? false : valid ? true : null"
                          />
                          <b-input-group-append is-text>
                            <b-form-checkbox
                              v-model="staffContactData.showEmail"
                              :disabled="!staffContactData.email"
                              :value="true"
                              :unchecked-value="false"
                            />
                          </b-input-group-append>
                        </b-input-group>
                        <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col class="label-text" md="6">
                    <validation-provider name="Teléfono" rules="">
                      <b-form-group slot-scope="{ valid, errors }">
                        <span class="d-flex justify-content-between">
                          <label>Teléfono</label> <label>Mostrar en web</label>
                        </span>
                        <b-input-group>
                          <b-form-input
                            v-model="staffContactData.phone"
                            @keypress="formatPhoneNumber"
                            :state="errors[0] ? false : valid ? true : null"
                          />
                          <b-input-group-append is-text>
                            <b-form-checkbox
                              v-model="staffContactData.showPhone"
                              :disabled="!staffContactData.phone"
                              :value="true"
                              :unchecked-value="false"
                            />
                          </b-input-group-append>
                        </b-input-group>
                        <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col class="label-text" md="6">
                    <validation-provider name="enlace de reunión" rules="">
                      <b-form-group slot-scope="{ valid, errors }">
                        <span class="d-flex justify-content-between">
                          <label>Enlace de reunión</label> <label>Mostrar en web</label>
                        </span>
                        <b-input-group>
                          <b-form-input
                            v-model="staffContactData.url"
                            :state="errors[0] ? false : valid ? true : null"
                            placeholder="https://"
                          />
                          <b-input-group-append is-text>
                            <b-form-checkbox
                              v-model="staffContactData.showUrl"
                              :disabled="!staffContactData.url"
                              :value="true"
                              :unchecked-value="false"
                            />
                          </b-input-group-append>
                        </b-input-group>
                        <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col md="">
                    <div class="mt-1">
                      <b-form-checkbox
                        v-model="staffContactData.status"
                        :value="true"
                        switch
                        :unchecked-value="false"
                        class="custom-control-success"
                      > Activo
                      </b-form-checkbox>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <div class="float-right">
                  <b-button
                    type="submit"
                    class="btn-block"
                    variant="primary"
                    :disabled="invalid"
                    > Actualizar
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </b-form>
        </ValidationObserver>
      </div>

      <b-alert show variant="danger" class="p-1 text-center" v-else>
        No se encontró información
      </b-alert>
    </b-card>
  </b-overlay>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import Ripple from 'vue-ripple-directive'
import { showAlertMessage, getLocalFilePath } from '@/helpers/helpers'
import { utils } from '@/modules/fivesClub/mixins/utils';

export default {
  mixins: [utils],
  directives: {Ripple, },
  async created() {
    await this.init()
  },
  data(){
    return {
      staffContactData: null,
      imgDefault: require("@/assets/images/default-profile.png"),
      isLoadingStaffContact: false,
      isUpdatingStaffContact: false,
      deleteFile: false,
      file: null,
      imgUrl: process.env.VUE_APP_IMG_SRC_API,
    }
  },
  watch: {
    staffContactData: {
			deep: true,
			async handler() {
        if (!this.staffContactData.email) this.staffContactData.showEmail = false
        if (!this.staffContactData.phone) this.staffContactData.showPhone = false
        if (!this.staffContactData.url) this.staffContactData.showUrl = false
			},
		}
  },
  computed: {
    ...mapState('auth', ['user']),

    sourceFile(){
      if (this.file && typeof this.file != 'string') return getLocalFilePath(this.file)
      else if (!!this.staffContactData.profilepicture) return this.imgUrl +'/'+ this.staffContactData.profilepicture
      else return null
    },
  },
  methods: {
    ...mapActions('fivesClubCatalogs', ['fetchStaffContactById','updateStaffContact']),

    async init(){
      const idStaffContact = this.$route.params.id
      this.isLoadingStaffContact = true
      this.staffContactData = null

      if( this.isANumber(idStaffContact) ){
        this.staffContactData = await this.fetchStaffContactById(idStaffContact)
      }

      this.isLoadingStaffContact = false
    },
    isANumber(string){
      return string ? !isNaN( string ) && string > 0 : false
    },
    async update() {
      this.isUpdatingStaffContact = true;

      const payload = {
        ...this.staffContactData,
        idUser: this.user.idUser,
        deleteFile: this.deleteFile
      }

      payload.profilepicture = null
      if (!!this.file) payload.profilepicture = this.file

      const { status, message } = await this.updateStaffContact(payload); // updateWebProp in backend

      if (status) {
        showAlertMessage('Ok: proceso finalizado', 'InfoIcon', message, 'success', 4000, 'bottom-right' )
        this.$router.push({ name: 'staff-contact' })
      }

      this.isUpdatingStaffContact = false;
    },
    imgAlternativo(event) {
      event.target.src = this.imgDefault
    },
    deleteImageLocal(){
      this.file = null
    },
    deleteImage(){
      this.staffContactData.profilepicture = null
      this.deleteFile = true
    },
  },
};
</script>

<style scoped>
.upload__img-wrap {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
}
.upload__img-box {
  width: 18rem;
  padding: 0 10px;
  margin-bottom: 1rem;
}
.img-bg {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  padding-bottom: 100%;
}
.default-image{
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  padding-bottom: 100%;
  background-image: url('~@/assets/images/default-profile.png');
}
.upload__img-close {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: rgba(179, 11, 11, 0.5);
  position: absolute;
  top: -10px;
  right: -10px;
  text-align: center;
  line-height: 24px;
  z-index: 1;
  cursor: pointer;
}
.upload__img-close:after {
  content: '\2716';
  font-size: 14px;
  color: white;
}



 .circle-img {
	border: 1px solid #28c76f;
	padding: 3px;
  width: 225px;
  height: 225px;
  border-radius: 100%;

  margin-block-end: 1rem;
}

.head-btn{
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
}
.button-delete-image{
  margin-bottom: 1rem;
  font-size: smaller;
}

.image-size{
  max-width: 225px;
  max-height: 100%
}

@media (max-width: 1280px) {
  .circle-img {
    border: 1px solid #03BFCB;
    padding: 3px;
    width: 180px;
    height: 180px;
    border-radius: 100%;

    margin-block-end: 1rem;
  }
  .circle-img .btn {
    margin: 0.5px;
  }
}

@media (max-width: 768px) {
  .circle-img {
    border: 1px solid #03BFCB;
    padding: 3px;
    width: 160px;
    height: 160px;
    border-radius: 100%;

    margin-block-end: 1rem;
  }

  .circle-img .btn {
    margin: 0.5px;
  }
}
</style>
